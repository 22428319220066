<template>
  <Navbar name="Transfer" />
  <div
    class="relative bg-white rounded-2xl -mt-3 p-4 z-10 h-[100vh] overflow-y-scroll"
  >
    <form class="flex flex-col gap-4" @submit.prevent="handleSubmit">
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Sender Name</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter sender name"
          autofocus
          v-model="formData.sender_name"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Sender Phone</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter sender phone"
          autofocus
          v-model="formData.sender_phone"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Sender National ID</label
        >
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter sender national ID"
          autofocus
          v-model="formData.sender_national_id"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Receiver Name</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter receiver name"
          autofocus
          v-model="formData.receiver_name"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Receiver Phone</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter receiver phone"
          autofocus
          v-model="formData.receiver_phone"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Receiver National ID</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="text"
          placeholder="Enter receiver national ID"
          v-model="formData.receiver_national_id"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Select Payment Method</label>
        <select
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          v-model="formData.payment_type_id"
        >
          <option value="" selected disabled>Choose your method</option>
          <option
            :value="payment.id"
            v-for="payment in paymentMethods"
            :key="payment.id"
          >
            {{ payment.name }}
          </option>
        </select>
      </div>
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1"
          >Select Receive Type</label>
        <select
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          v-model="formData.receive_type">
          <option value="" disabled>Choose your receive type</option>
          <option
            value="cash"
            selected
            v-if="$store.getters.getAgent.type === 'agent'">
            Cash
          </option>
          <option
            value="bank"
            selected
            v-if="$store.getters.getAgent.type === 'admin'">
            Bank
          </option>
        </select>
      </div>
      <hr class="text-gray-700 mt-4 mb-2" />
      <div class="flex flex-col gap-1">
        <label for="" class="block font-semibold ml-1">Amount</label>
        <input
          class="block w-full px-4 py-2 rounded-lg bg-yellow-200 focus:border-none focus:outline-none"
          type="number"
          placeholder="Enter amount"
          v-model="formData.amount"
        />
      </div>
      <div class="flex flex-col gap-2">
        <input
          class="block w-full p-2 rounded-lg font-semibold bg-yellow-400 cursor-pointer"
          type="submit"
          value="Submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import makeHttpRequest from "@/js/makeHttpRequest";
export default {
  components: { Navbar },
  name: "TransferPage",
  data() {
    return {
      token: "",
      transactions: [],
      paymentMethods: [],
      formData: {
        sender_name: "",
        sender_phone: "",
        receiver_name: "",
        receiver_phone: "",
        amount: "",
        sender_national_id: "",
        receiver_national_id: "",
        payment_type_id: "",
        receive_type: "",
      },
      transaction: {},
    };
  },
  methods: {
    async getPaymentTypes() {
      const url = this.$store.getters.getPaymentTypesUrl;
      try {
        const data = await makeHttpRequest(url, this.token);
        if (data.success) {
          this.paymentMethods = data.data;
        } else {
          console.log(data);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async handleSubmit() {
      console.log(this.formData, this.token);
      const url = "https://shwehninsi.smartcodemm.com/api/transfer-agent/transaction/"+this.transaction.id+"/update";
      try {
        const data = await makeHttpRequest(
          url,
          this.token,
          "POST",
          this.formData
        );
        if (data.success) {
          this.formData.date = new Date();
          this.$store.commit("updateTransactions", this.formData);
          // console.log(this.$store.state.transactions);
          this.$router.push({ name: "home" });
        } else {
          console.log(data);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  mounted() {
    this.token = this.$store.getters.getToken;
    this.getPaymentTypes();
    const transaction = JSON.parse(
      decodeURIComponent(this.$route.query.transaction)
    );
    this.transaction = transaction;
    console.log(transaction)
    this.formData.sender_name = transaction.sender_name;
    this.formData.sender_phone = transaction.sender_phone;
    this.formData.sender_national_id = transaction.sender_national_id;
    this.formData.receiver_name = transaction.receiver_name;
    this.formData.receiver_phone = transaction.receiver_phone;
    this.formData.receiver_national_id = transaction.receiver_national_id;
    this.formData.amount = transaction.amount;
    this.formData.payment_type_id = transaction.payment_type.id;
    this.formData.receive_type = transaction.receive_type;
  },
};
</script>
