export default async function fetchRequest(
  url,
  token = "",
  method = "GET",
  body = ""
) {
  try {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    if (method === "POST") {
      options.headers["Accept"] = "application/json";
    }

    const response = await fetch(url, options);

    if (!response) {
      throw new Error("Req failed!");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
