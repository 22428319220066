<template>
  <Navbar name="Transfer History" />
  <div id="default-modal" v-show="showAlert" tabindex="-1" aria-hidden="true" class="overflow-y-auto bg-black bg-opacity-50 overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-full">
      <div class="relative p-4 w-full max-w-2xl max-h-full">
          <!-- Modal content -->
          <div v-show="showAlert" class="relative bg-white rounded-lg shadow dark:bg-white-700">
              <div class="p-4 md:p-5 py-6 space-y-4">
                  <h3 class="text-xl text-center font-semibold text-gray-900 dark:text-gray-600">Are you sure want to incorrect ?</h3>
              </div>
              <!-- Modal footer -->
              <div class="flex justify-between items-center p-4 md:p-5 border-t border-gray-300 rounded-b dark:border-gray-400">
                <button data-modal-hide="default-modal" @click="showAlert=false" type="button" class="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Later</button>
                <button data-modal-hide="default-modal" @click="incorrectTransaction(transactions.id)" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Sure</button>
              </div>
          </div>
      </div>
  </div>
  
  <div class="w-full h-full bg-gray-100">
    <div class="relative bg-gray-100 rounded-2xl -mt-3 p-6 z-10"><div class="bg-white rounded-xl pt-2 pb-4">
        <div v-if="[1,3].includes(transactions.status)" class="w-full flex justify-between px-2 mt-1">
          <router-link
          :to="{
            name: 'receiverTransfer',
            query: { transaction: encodeURIComponent(JSON.stringify(transactions)) },
          }"
          class="border-yellow-300 border-2 bg-yellow-400 py-1 px-3 text-lg rounded-md">
            <font-awesome-icon icon="fa-solid fa-right-left" />
          </router-link>
          <button v-if="transactions.status==1" @click="showAlert=true" class="border-red-300 border-2 text-lg inline bg-red-400 py-1 px-3 rounded-md">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
        </div>
        <font-awesome-icon icon="fa-solid fa-circle-check" class="text-5xl text-yellow-400 text-center w-full" />
        <div class="flex flex-col items-center justify-center">
          <p class="text-gray-500">Success</p>
          <p class="text-gray-800 font-semibold text-xl">
            + {{ Number(transactions.amount).toLocaleString() }} Ks
          </p>
        </div>
      </div>
      <div
        class="w-[90%] mx-auto border-gray-300 border-t-[2px] border-dashed"
      ></div>
      <div class="flex flex-col gap-4 bg-white rounded-xl p-4">
        <div class="flex justify-between text-sm text-gray-700">
          <p>Voucher Id</p>
          <p>{{ transactions.voucher_id }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Sender Name</p>
          <p>{{ transactions.sender_name }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Sender Phone</p>
          <p>{{ transactions.sender_phone }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Receiver Name</p>
          <p>{{ transactions.receiver_name }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Receiver Phone</p>
          <p>{{ transactions.receiver_phone }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Payment Method</p>
          <p v-if="paymentType != null">{{paymentType.name}}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Amount</p>
          <p>{{ Number(transactions.amount).toLocaleString() }} Ks</p>
        </div>
        <!-- <div class="flex justify-between text-sm text-gray-700">
          <p>Total Amount</p>
          <p>{{ Number(transactions.total_charges).toLocaleString() }} RM</p>
        </div> -->
        <div class="flex justify-between text-sm text-gray-700">
          <p>Created Date</p>
          <p>{{createdDate}}</p>
        </div>
      </div>
    </div>
    <!-- <div class="px-4">
      <h3 class="text-center border-0 border-b-[1px] border-gray-400 pb-2">Receiver Payments</h3>
      <ul class="h-[80vh] overflow-y-scroll">
      <router-link
        :to="{
          name: 'receiverTransaction',
          query: { transaction: encodeURIComponent(JSON.stringify(item)) },
        }"
        class="flex justify-between py-4 border-b-[1px] border-gray-300 px-2"
        v-for="item in receiverTransactions"
        :key="item.receiver_phone"
      >
        <div class="flex items-center gap-4">
          <font-awesome-icon icon="fa-solid fa-dollar-sign" class="block text-xl text-yellow-400" />
          <div class="flex flex-col items-start">
            <p class="text-base font-bold text-gray-800">
              {{ item.receiver_phone }}
            </p>
            <p class="text-sm text-gray-500 font-semibold">{{ item.date }}</p>
          </div>
        </div>
        <div class="flex flex-col justify-center items-end">
          <h2 class="text-green-700 font-bold">
            {{ item.sign }} {{ item.amount }} {{ item.currencyCode ?? "Ks" }}
          </h2>
        </div>
      </router-link>
    </ul>
    </div> -->
  </div>
</template>

<script>
import Navbar from "./Navbar";
import dayjs from "dayjs";
import makeHttpRequest from "@/js/makeHttpRequest";
import router from '@/router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign, faRightLeft, faXmark, faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

library.add(faDollarSign, faRightLeft, faXmark, faCircleCheck, faCircleExclamation)

export default {
  components: { Navbar, FontAwesomeIcon },
  data() {
    return {
      showAlert: false,
      transactions: [],
      receiverTransactions: [],
      paymentType: {}
    };
  },
  computed: {
    createdDate() {
      return dayjs(this.transactions.created_at).format('DD/MM/YY h:m:s A');
    }
  },
  mounted() {
    const transaction = JSON.parse(
      decodeURIComponent(this.$route.query.transaction)
    );
    this.transactions = transaction;
    this.paymentType = transaction.payment_type;
    console.log(transaction);
    // this.getReceiverTransactions()
    // this.updateAgentViewedStatus(this.transactions.id)
  },
  methods: {
    // async getReceiverTransactions() {
    //   try {
    //     const url = "https://shwehninsi.smartcodemm.com/api/transfer-agent/transaction/"+this.transactions.id+"/receiver-transactions";
    //     const token = this.$store.getters.getToken;
    //     const data = await makeHttpRequest(url, token);
    //     if (data.success) {
    //       console.log(data.data)
    //       this.receiverTransactions = data.data;
    //     } else {
    //       console.log(data);
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // },
    // async incorrectTransaction(id) {
    //   try {
    //     const url = "https://shwehninsi.smartcodemm.com/api/receive-agent/transaction/"+id+"/incorrect";
    //     const token = this.$store.getters.getToken;
    //     const data = await makeHttpRequest(url, token, "POST");
    //     if (data.success) {
    //       router.push("transfer/history")
    //     } else {
    //       console.log(data);
    //     }
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // },
    // updateAgentViewedStatus(id) {
    //   console.log(this.transactions.is_receive_agent_viewed)
    //   if(this.transactions.is_receive_agent_viewed===0) {
    //     console.log('agent not veiw')
    //     try {
    //       const url = "https://shwehninsi.smartcodemm.com/api/receive-agent/transaction/"+id+"/viewed";
    //       const token = this.$store.getters.getToken;
    //       const data = makeHttpRequest(url, token, "GET");
    //       if (data.success) {
    //         console.log('agent viewed')
    //       } else {
    //         console.log(data);
    //       }
    //     } catch (error) {
    //       console.log(error.message);
    //     }
    //   } else {console.log('agent alerady viewed')}
    // }
  }
};
</script>
