export default function formatDateTime(currentDate) {
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }).format(currentDate);

  const formattedDateTime = `${month}/${day} ${formattedTime}`;

  return formattedDateTime;
}
