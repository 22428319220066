<template>
  <Navbar name="Receive History" />
  <div class="relative bg-white rounded-2xl -mt-3 p-4 z-10">
    <div class="flex justify-end pb-3 border-b-[1px] border-gray-300">
      <input
        type="date"
        class="text-gray-300 focus:border-none focus:outline-none"
        v-model="date"
      />
    </div>
    <ul class="h-[82vh] overflow-y-scroll">
      
      <div class="flex justify-between py-4 border-b-[1px] border-gray-300 px-2"
        v-for="item in agentTransactions"
        :key="item">
        <div class="flex items-center gap-4">
          <font-awesome-icon icon="fa-solid fa-dollar-sign" class="block text-xl text-yellow-400" />
          <div class="flex flex-col items-start">
            <!-- <h2 class="text-sm font-semibold">Thuta Ye Aung</h2> -->
            <p class="text-base font-bold text-gray-800">
              {{ item.note }}
            </p>
            <p class="text-sm text-gray-500 font-semibold">
              {{ formatDate(item.created_at) }}
            </p>
          </div>
        </div>
        <div class="flex flex-col justify-center items-end">
          <h2 class="text-green-700 font-bold" v-if="item.action=='added'">
            {{ '+ '+item.amount }} {{ item.currencyCode ?? "Ks" }}
          </h2>
          <h2 class="text-red-700 font-bold" v-else>
            {{ '- '+item.amount }} {{ item.currencyCode ?? "Ks" }}
          </h2>
        </div>
        </div>
    </ul>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import dayjs from "dayjs";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

library.add(faDollarSign)

export default {
  name: "TransferHistory",
  components: { Navbar, FontAwesomeIcon },
  data() {
    return {
      transactions: this.$store.state.transactions,
      agentTransactions: [],
      date: null,
      token: '',
    };
  },
  methods: {
    // changeTab(index) {
    //   this.activeTabIndex = index;
    // },
    // formatDateTime(date) {
    //   return formatDateTime(date);
    // },
    formatDate(date) {
      return dayjs(date).format('DD/MM/YY h:m:s A');
    },
    getAgentTransactions() {
      try {
        const url = this.$store.getters.getAgentTransactionsUrl;
        axios.get(url, {
            params: {date: this.date},
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            }
          })
          .then(resp => this.agentTransactions = resp.data.data)
          .catch(err => console.log(err))
        } catch (err) {console.log(err.message)}
    },  
  },
  mounted() {
    this.token = this.$store.getters.getToken;
    this.getAgentTransactions()
  },
  watch: {
    date(value) {
      this.value = value;
      console.log(value)
      this.getAgentTransactions()
    }
  }
};
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}
</style>
