<template>
  <div class="w-full h-full bg-white">
    <div class="bg-yellow-400 px-4 pt-4 pb-8">
      <img
        src="https://shwehninsi.smartcodemm.com/shwehninsi_logo.png"
        class="w-12 h-12 mb-4"
        alt=""
      />
      <div class="flex items-center gap-4 bg-white rounded-xl px-4 py-3">
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIy2vRwSRoUACatub962auO36Uo5OjNQ5wCQ&usqp=CAU"
          class="block w-14 h-14 rounded-full"
          alt=""
        />
        <div class="flex flex-col">
          <h2 class="font-semibold text-md">{{ agent.name }} <span class="text-gray-600">{{"( "+ agent.phone +" )"}}</span></h2>
          <!-- <p class="font-bold text-red-600">
            <span>{{ agent.balance }}</span> MMK
          </p> -->
        </div>
      </div>
    </div>
    <div
      class="flex justify-center bg-white rounded-t-2xl gap-4 -mt-4 px-8 py-4 mb-2"
    >
      <!-- <router-link
        :to="{ name: 'transfer' }"
        class="flex flex-col gap-1 items-center w-2/4"
      >
        <i
          class="fa-solid fa-dollar-sign block w-full bg-yellow-400 text-center text-red-600 text-4xl px-4 py-5 rounded-xl"
        ></i>
        <p class="text-lg">Transfer</p>
      </router-link> -->
      <!-- <router-link
        :to="{ name: 'transferHistory' }"
        class="flex flex-col gap-1 items-center w-2/4"
      >
        <i
          class="fa-solid fa-clock block w-full bg-yellow-400 text-center text-red-600 text-4xl px-4 py-5 rounded-xl"
        ></i>
        <p class="text-lg">History</p>
      </router-link> -->
    </div>
    <div class="px-8 mb-4">
      <router-link
        :to="{ name: 'transferHistory' }"
        class="flex gap-2 justify-between items-center w-full bg-yellow-400 px-6 rounded-xl"
      >
        <p class="text-lg font-semibold">See Transactions</p>
        <font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="block text-red-600 text-4xl py-7" />
      </router-link>
    </div>
    <!-- <div class="px-8">
      <router-link
        :to="{ name: 'receiveHistory' }"
        class="flex gap-2 justify-between items-center w-full bg-yellow-400 px-6 rounded-xl"
      >
        <p class="text-lg font-semibold">See Transactions History</p>
        <font-awesome-icon icon="fa-solid fa-circle-chevron-right" class="block text-red-600 text-4xl py-7" />
      </router-link>
    </div> -->
  </div>
</template>

<script>
import makeHttpRequest from "@/js/makeHttpRequest";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons'

library.add(faCircleChevronRight)

export default {
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      agent: [],
    };
  },
  methods: {
    async getAgentDetail() {
      try {
        const url = this.$store.getters.getAgentDetailUrl;
        const token = this.$store.getters.getToken;
        const data = await makeHttpRequest(url, token);
        if (data.success) {
          this.$store.commit("updateAgent", data.data);
          this.agent = data.data;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  mounted() {
    this.getAgentDetail();
  },
};
</script>
