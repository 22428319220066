import { createStore } from "vuex";

export default createStore({
  state: {
    loginUrl: "https://shwehninsi.smartcodemm.com/api/receive-agent/login",
    logoutUrl: "https://shwehninsi.smartcodemm.com/api/logout",
    createReceiverTransactionUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/receiver-transaction/create",
    transactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/transactions",
    partialTransactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/transactions/partial-completed",
    pendingTransactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/transactions/pending",
    completedTransactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/transactions/completed",
    incorrectTransactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/transactions/incorrect",
    cancelledTransactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/transfer-agent/transactions/cancelled",
    paymentTypesUrl:
      "https://shwehninsi.smartcodemm.com/api/transfer-agent/payment-type",
    agentDetailUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/details",
    receiverTransactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/transfer-agent/transaction/1/receiver-transactions",
    agentTransactionsUrl:
      "https://shwehninsi.smartcodemm.com/api/receive-agent/agent-transaction",
    token: "",
    transactions: [],
    agent: [],
  },
  getters: {
    getLoginUrl: (state) => state.loginUrl,
    getLogoutUrl: (state) => state.logoutUrl,
    getCreateReceiverTransactionUrl: (state) => state.createReceiverTransactionUrl,
    getTransactionsUrl: (state) => state.transactionsUrl,
    getPartialTransactionsUrl: (state) => state.partialTransactionsUrl,
    getPendingTransactionsUrl: (state) => state.pendingTransactionsUrl,
    getCompletedTransactionsUrl: (state) => state.completedTransactionsUrl,
    getIncorrectTransactionsUrl: (state) => state.incorrectTransactionsUrl,
    getCancelledTransactionsUrl: (state) => state.cancelledTransactionsUrl,
    getAgentTransactionsUrl: (state) => state.agentTransactionsUrl,
    getPaymentTypesUrl: (state) => state.paymentTypesUrl,
    getAgentDetailUrl: (state) => state.agentDetailUrl,
    getReceiverTransactionsUrl: (state) => state.receiverTransactionsUrl,
    getToken: (state) => state.token,
    getTransactions: (state) => state.transactions,
    getAgent: (state) => state.agent,
  },
  mutations: {
    updateToken(state, value) {
      state.token = value;
    },
    updateTransactions(state, value) {
      state.transactions.push(value);
    },
    updateAgent(state, value) {
      state.agent = value;
    },
  },
  actions: {},
  modules: {},
});
