import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';

const app = createApp(App)
app.use(Toast, {
    position: 'top'
})
app.use(store)
app.use(router)
app.mount('#app')
