<template>
  <Navbar name="Transfer History" />
  <div class="w-full h-full bg-gray-100">
    <div class="relative bg-gray-100 rounded-2xl -mt-3 p-6 z-10">
      <div class="bg-white rounded-xl py-4">
        <i
          class="fa-solid fa-circle-check text-5xl text-yellow-400 text-center w-full"
        ></i>
        <div class="flex flex-col items-center justify-center">
          <p class="text-gray-500">Success</p>
          <p class="text-gray-800 font-semibold text-xl">
            + {{ Number(transactions.amount).toLocaleString() }} Ks
          </p>
        </div>
      </div>
      <div
        class="w-[90%] mx-auto border-gray-300 border-t-[2px] border-dashed"
      ></div>
      <div class="flex flex-col gap-4 bg-white rounded-xl p-4">
        <div class="flex justify-between text-sm text-gray-700">
          <p>Receiver Name</p>
          <p>{{ transactions.receiver_name }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Receiver Phone</p>
          <p>{{ transactions.receiver_phone }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Payment Method</p>
          <p>{{ transactions.payment_type }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Transaction Id</p>
          <p>{{ transactions.third_party_transaction_id }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Password</p>
          <p>{{ transactions.password }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Note</p>
          <p>{{ transactions.note }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Created Date</p>
          <p>{{createdDate}}</p>
        </div>
        <div>
          <img :src="transactions.image" class="w-100 rounded-md" alt="Payment confirm img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import dayjs from "dayjs";

export default {
  components: { Navbar },
  data() {
    return {
      transactions: [],
      receiverTransactions: []
    };
  },
  computed: {
    createdDate() {
      return dayjs(this.transactions.created_at).format('DD/MM/YY h:m:s A');
    }
  },
  mounted() {
    const transaction = JSON.parse(
      decodeURIComponent(this.$route.query.transaction)
    );
    this.transactions = transaction;
    console.log('rece'+this.transactions)
  },
};
</script>
