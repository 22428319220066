<template>
  <Logout></Logout>
  <router-view />
</template>

<script>
import Logout from "./components/Logout";
import LoginPage from "./views/LoginPage.vue";
export default {
  components: {
    Logout,
    LoginPage,
  },
};
</script>
