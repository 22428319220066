<template>
  <Navbar name="Transfer History" />
  <div class="relative bg-white rounded-t-2xl -mt-3 p-4 z-10">
    <div class="flex justify-end pb-3 border-b-[1px] border-gray-300">
      <input
        type="date"
        id="date"
        v-model="date"
        class="text-gray-300 focus:border-none focus:outline-none"
      />
    </div>
    <ul class="h-full">
      <router-link
        :to="{
          name: 'transaction',
          query: { transaction: encodeURIComponent(JSON.stringify(item)) },
        }"
        class="flex justify-between py-4 border-b-[1px] border-gray-300 px-2"
        v-for="item in transactions"
        :key="item.receiver_phone"
      >
        <div class="flex items-center gap-4">
          <font-awesome-icon icon="fa-solid fa-dollar-sign" class="block text-xl text-yellow-400" />
          <div class="flex flex-col items-start">
            <p class="text-base font-bold text-gray-800">
              {{ item.receiver_phone }}
            </p>
            <p class="text-sm text-gray-500 font-semibold">{{ item.date }}</p>
          </div>
        </div>
        <div class="flex flex-col justify-center items-end">
          <h2 class="text-green-700 font-bold">
            {{ item.sign }} {{ item.amount }} {{ item.currencyCode ?? "Ks" }}
          </h2>
        </div>
      </router-link>
    </ul>
  </div>
  <!-- <div class="z-10 fixed bottom-0 w-full">
    <div class="grid grid-cols-4 bg-yellow-400 h-16">
      <div
        v-for="(tab, i) in tabs" :key="i"
        @click="changeTab(i)"
        class="font-semibold text-sm capitalize flex justify-center items-center"
        :class="{
          'text-black bg-yellow-200 border-[2px] border-transparent':
            i === activeTabIndex,
          'bg-transparent border-[2px] border-yellow-400':
            i !== activeTabIndex,
        }"
      >
        {{ tab }}
      </div>
    </div>
  </div> -->
</template>

<script>
import Navbar from "./Navbar";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

library.add(faDollarSign)

export default {
  name: "TransferHistory",
  components: { Navbar, FontAwesomeIcon },
  data() {
    return {
      // tabs: ["complete", "partial", "ongoing", "incorrect"],
      // activeTab: "complete",
      // activeTabIndex: 0,
      transactions: [],
      // completeTransactions: [],
      // partialTransactions: [],
      // pendingTransactions: [],
      // incorrectTransactions: [],
      date: null,
      token: '',
    };
  },
  mounted() {
    this.token = this.$store.getters.getToken;
    this.getTransactions();
    // this.getPartialTransactions();
    // this.getPendingTransactions();
    // this.getIncorrectTransactions();
    // this.transactions = this.completeTransactions;
  },
  methods: {
    // async changeTab(index) {
    //   this.activeTabIndex = index;
    //   this.activeTab = this.tabs[index];
    //   if (this.activeTab === "complete")
    //     this.transactions = this.completeTransactions;
    //   if (this.activeTab === "partial")
    //     this.transactions = this.partialTransactions;
    //   if (this.activeTab === "ongoing")
    //     this.transactions = this.pendingTransactions;
    //   if (this.activeTab === "incorrect")
    //     this.transactions = this.incorrectTransactions;
    // },

    getTransactions() {
      try {
        const url = this.$store.getters.getTransactionsUrl;
        axios.get(url, {
            params: {date: this.date},
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${this.token}`,
            }
          })
          .then(resp => {
            if(this.activeTab==="complete") {
              this.transactions = resp.data.data;
            }
            this.transactions = resp.data.data;
          })
          .catch(err => console.log(err))
        } catch (err) {console.log(err.message)}
    },

    // getPartialTransactions() {
    //   try {
    //     const url = this.$store.getters.getPartialTransactionsUrl;
    //     axios.get(url, {
    //         params: {date: this.date},
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': `Bearer ${this.token}`,
    //         }
    //       })
    //       .then(resp => {
    //         if(this.activeTab==="partial") {
    //           this.transactions = resp.data.data;
    //         } else {
    //           this.partialTransactions  = resp.data.data;
    //         }
    //       })
    //       .catch(err => console.log(err))
    //     } catch (err) {console.log(err.message)}
    // },

    // getPendingTransactions() {
    //   try {
    //     const url = this.$store.getters.getPendingTransactionsUrl;
    //     axios.get(url, {
    //         params: {date: this.date},
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': `Bearer ${this.token}`,
    //         }
    //       })
    //       .then(resp => {
    //         console.log(resp)
    //         if(this.activeTab==="ongoing") {
    //           this.transactions = resp.data.data;
    //         } else {
    //           this.pendingTransactions  = resp.data.data;
    //         }
    //       })
    //       .catch(err => console.log(err))
    //     } catch (err) {console.log(err.message)}
    // },

    // getIncorrectTransactions() {
    //   try {
    //     const url = this.$store.getters.getIncorrectTransactionsUrl;
    //     axios.get(url, {
    //         params: {date: this.date},
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           'Authorization': `Bearer ${this.token}`,
    //         }
    //       })
    //       .then(resp => {
    //         if(this.activeTab==="incorrect") {
    //           this.transactions = resp.data.data;
    //         } else {
    //           this.incorrectTransactions  = resp.data.data;
    //         }
    //       })
    //       .catch(err => console.log(err))
    //     } catch (err) {console.log(err.message)}
    // },

  },
  watch: {
    date(value) {
      this.value = value;
      console.log(value)
      this.getCompletedTransactions()
      this.getPartialTransactions()
      this.getPendingTransactions()
      this.getIncorrectTransactions()
    }
  }
};
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}
</style>
