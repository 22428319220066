<template>
  <Navbar name="Receive History" />
  <div class="w-full h-full bg-gray-100">
    <div class="relative bg-gray-100 rounded-2xl -mt-3 p-6 z-10">
      <div class="bg-white rounded-xl py-4">
        <i
          class="fa-solid fa-circle-check text-5xl text-yellow-400 text-center w-full"
        ></i>
        <div class="flex flex-col items-center justify-center">
          <p class="text-gray-500">Success</p>
          <p class="text-gray-800 font-semibold text-xl">+ 5,000 Ks</p>
        </div>
      </div>
      <div
        class="w-[90%] mx-auto border-gray-300 border-t-[2px] border-dashed"
      ></div>
      <div class="flex flex-col gap-4 bg-white rounded-xl p-4">
        <div class="flex justify-between text-sm text-gray-700">
          <p>Receiver No.</p>
          <p>{{ transaction.receiver_phone }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Date</p>
          <p>{{ date }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Sender Name</p>
          <p>{{ transaction.sender_name }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Sender No.</p>
          <p>{{ transaction.sender_phone }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Payment Method</p>
          <p>{{ selectedPaymentMethod }}</p>
        </div>
        <div class="flex justify-between text-sm text-gray-700">
          <p>Amount</p>
          <p>{{ Number(transaction.amount).toLocaleString() }} Ks</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import makeHttpRequest from "@/js/makeHttpRequest";
import formatDateTime from "@/js/formatDateTime";
export default {
  components: { Navbar },
  data() {
    return {
      selectedPaymentMethod: "",
      transaction: [],
      date: "",
    };
  },
  methods: {
    async getPaymentTypes() {
      const url = this.$store.getters.getPaymentTypesUrl;
      try {
        const data = await makeHttpRequest(url, this.$store.getters.getToken);
        if (data.success) {
          const selectedPaymentArr = data.data.filter(
            (ele) => ele.id === +this.transaction["payment_type_id"]
          );
          this.selectedPaymentMethod = selectedPaymentArr[0]["name"];
        } else {
          console.log(data);
        }
      } catch (error) {
        console.log(error.message);
      }
    },

    formatDateTime(date) {
      return formatDateTime(date);
    },
  },
  mounted() {
    this.getPaymentTypes();
    const getTransaction = JSON.parse(
      decodeURIComponent(this.$route.query.transaction)
    );
    this.transaction = getTransaction;
    this.date = JSON.parse(decodeURIComponent(this.$route.query.date));
    console.log(this.transaction);
  },
};
</script>
