import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import LoginPage from "../views/LoginPage";
import HomePage from "../views/HomePage";
import TransferPage from "@/components/TransferPage.vue";
import TransferHistory from "@/components/TransferHistory.vue";
import Transaction from "@/components/Transaction.vue";
import ReceiveHistory from "@/components/ReceiveHistory.vue";
import ReceiveTransaction from "@/components/ReceiveTransaction.vue";
import ScreenshotTransaction from "@/components/ScreenshotTransaction.vue";
import EditTransaction from '@/components/EditTransferPage.vue';


const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
    beforeEnter(to, from, next) {
      const token = store.getters.getToken;
      const isAuthenticated = token;
      if (isAuthenticated) next({ ...to });
      else next();
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/receiver/transfer",
    name: "receiverTransfer",
    component: TransferPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/transfer/history",
    name: "transferHistory",
    component: TransferHistory,
    meta: { requiresAuth: true },
  },
  {
    path: "/receive/history",
    name: "receiveHistory",
    component: ReceiveHistory,
    meta: { requiresAuth: true },
  },
  {
    path: "/transaction",
    name: "transaction",
    component: Transaction,
    meta: { requiresAuth: true },
  },
  {
    path: "/transaction/edit",
    name: "editTransaction",
    component: EditTransaction,
    meta: { requiresAuth: true },
  },
  {
    path: "/receiver/transaction",
    name: "receiverTransaction",
    component: ScreenshotTransaction,
    meta: { requiresAuth: true },
  },
  {
    path: "/receive/transaction",
    name: "receiveTransaction",
    component: ReceiveTransaction,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = store.getters.getToken;
    const isAuthenticated = token;

    if (!isAuthenticated) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
